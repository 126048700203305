<template>

    <form>
        <div class="login-body">
            <div class="login-wrapper">
                <div class="login-panel">
                    <a @click="goDashboard" class="logo">
                        <img src="../../public/logo-admin.png" alt="freya-layout" style="width: 150px; height: 125px;" />
                    </a>
                    
                    <InputText v-model="state.username" id="username" placeholder="Usuário" class="mb-1"/>
                    <Password v-model="state.password" id="password" placeholder="Senha" :feedback="false" />
                    <Button label="ENTRAR" :loading="state.loading" type="submit" @click="tryToLogIn"></Button>
                        
                    <p>Copyright © 2024</p>
                </div>
            </div>
        </div>
    </form>

    <NotificationToast />

</template>



<script>
import { reactive } from 'vue'
import { useStore } from 'vuex'
import services from '../services'
import { useRouter } from 'vue-router'
import NotificationToast from "@/components/NotificationToast"
import useNotificationToast from '../composables/useNotificationToast'

export default {
    
    components: {NotificationToast},

    setup() {

        const state = reactive({
            username: '',
            password: '',
            admin: true,
            loading: false,
            expired: false,
        })

        const { error,info } = useNotificationToast()

        const store = useStore()

        const router = useRouter()


        async function tryToLogIn() {
            state.loading = true
            
            if(!state.username || !state.password){
                state.loading = false
                return info('Preencha os campos de login.')
            }

            try{
                const response = await services.auth.login({login: state.username,password: state.password, admin: state.admin})
                const {token , usuario} = response.data.data

                store.dispatch('setLogged', {
                    token: `Bearer ${token}`,
                    isLogged: true
                })

                store.dispatch('setUser', {
                    usuario: usuario
                })

                router.push({ name: 'dashboard' })
            }
            catch(e){
                const errorMessage = e?.response?.data?.message || e?.response?.message || 'Não foi possível carregar os dados do usuário!'
                error(errorMessage)
            }
            finally{
                state.loading = false
            }
        }

        return {
            state,
            tryToLogIn
        }
    }
}
</script>
