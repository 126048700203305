<template>
    <div class="menu-wrapper" :class="{ 'layout-sidebar-active': sidebarActive }" @click="onMenuClick"
        @mouseover="onSidebarMouseOver" @mouseleave="onSidebarMouseLeave">
        <div class="sidebar-logo">
            <router-link to="/">
                <img src="../public/logo-admin.png" alt="logo-bingo" style="width: 25px; height: 20px;">
            </router-link>
            <a class="sidebar-pin" @click="onToggleMenu($event)">
                <span class="pin"></span>
            </a>
        </div>

        <div class="layout-menu-container">
            <AppSubmenu class="layout-menu" :items="filtrarMenu" :layoutMode="layoutMode" :parentMenuItemActive="true"
                :menuActive="menuActive" :mobileMenuActive="mobileMenuActive" :root="true"
                @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import AppSubmenu from './AppSubmenu.vue'

export default {
    name: "AppMenu",
    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "toggle-menu", "sidebar-mouse-over", "sidebar-mouse-leave"],
    props: {
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean
    },

    components: { AppSubmenu },

    setup(props, { emit }) {

        const store = useStore()
        const isAdmin = computed(() => store.getters.isAdmin)
        const isOperator = computed(()=> store.getters.isOperator)
        const isGestor = computed(()=> store.getters.isGestor)
        const isProgramador = computed(()=> store.getters.isProgramador)
        const isGerenteSecao = computed(()=> store.getters.isGerenteSecao)

        const baseMenu = ref([
            { label: "Dashboard", icon: "pi pi-chart-line", to: "/" },
            {
                label: "Usuários", icon: "pi pi-users",
                items: [
                    { label: "Admins", icon: "pi pi-user", to: "/usuarios/admins" },
                    { label: "Gestores", icon: "pi pi-user", to: "/usuarios/gestores" },
                    { label: "Gerentes de Seção", icon: "pi pi-user", to: "/usuarios/gerente-secao" },
                    { label: "Operadores", icon: "pi pi-user", to: "/usuarios/operadores" },
                    { label: "Programadores", icon: "pi pi-user", to: "/usuarios/programadores" },
                    { label: "Estabelecimentos", icon: "pi pi-building", to: "/estabelecimentos" },
                ],
            },
            { label: "Partidas", icon: "pi pi-flag", to: "/partidas" },
            { label: "Regiões", icon: "pi pi-map-marker", to: "/regioes" },
            { label: "Seções", icon: "pi pi-map", to: "/secoes" },
            { label: "Rotas", icon: "pi pi-directions-alt", to: "/rotas" },
            { 
                label: "Financeiro", icon: "pi pi-dollar",
                items:[
                    {label: "Recolhe", icon:"pi pi-dollar", to: "/relatorios/recolhe"},
                    {label: "Posição do Recolhe", icon: "pi pi-dollar", to: "/relatorios/posicaorecolhe"},
                ]
            },
            { 
                label: "Relatórios", icon: "pi pi-file-edit",
                items:[
                    {label: "Cobrança", icon: "pi pi-dollar", to: "/relatorios/cobranca"},
                    {label: "Ganhadores", icon: "pi pi-star", to: "/relatorios/ganhadores"},
                    {label: "Por Seção", icon: "pi pi-map", to: "/relatorios/secao"},
                    {label: "Por Sorteio", icon:"pi pi-globe", to: "/relatorios/sorteio"},
                    {label: "Por Estabelecimento", icon: "pi pi-building", to: "/relatorios/estabelecimento"},
                ]
            },
        ])


        const filtrarMenu = computed(() => {
            let menu = [...baseMenu.value]

            //se for admin
            if (isAdmin.value) {
                menu = menu.filter(item => item.label !== "Partidas")
            }

            //se for gestor = region_manager
            if(isGestor.value){
                menu = menu.filter(item => item.label !== "Regiões" && item.label !== "Partidas")

                menu = menu.map(item => {
                    if (item.items) {
                        item.items = item.items.filter(subItem => subItem.label !== "Admins" && subItem.label !== "Gestores" && 
                        subItem.label !== "Programadores")
                    }
                    return item
                })
            }

            //se for operador
            if(isOperator.value){
                menu = menu.filter(item => item.label === "Financeiro")

                menu = menu.map(item => {
                    if (item.items) {
                        item.items = item.items.filter(subItem => subItem.label === "Recolhe" || subItem.label === "Posição do Recolhe")
                    }
                    return item
                })
            }

            //se for programador
            if(isProgramador.value){
                menu = menu.filter(item => item.label === "Partidas")
            }


            if(isGerenteSecao.value){
                menu = menu.filter(item => ["Relatórios", "Usuários", "Financeiro", "Seções", "Rotas"].includes(item.label))

                menu = menu.map(item => {
                    if(item.items){
                        item.items = item.items.filter(subItem => 
                            ["Recolhe", "Posição do Recolhe", "Por Seção", "Por Estabelecimento", "Por Sorteio", "Operadores", "Estabelecimentos"].includes(subItem.label)
                        )
                    }
                    return item
                })
            }

            return menu
        })


        const onSidebarMouseOver = () => {
            if (props.layoutMode === "sidebar" && !props.sidebarStatic) {
                emit("sidebar-mouse-over")
            }
        }

        const onSidebarMouseLeave = () => {
            if (props.layoutMode === "sidebar" && !props.sidebarStatic) {
                setTimeout(() => {
                emit("sidebar-mouse-leave")
                }, 250)
            }
        }

        const onToggleMenu = (event) => {
            emit("toggle-menu", event)
        }

        const onMenuClick = (event) => {
            emit("menu-click", event)
        }

        const onMenuItemClick = (event) => {
            emit("menuitem-click", event)
        }

        const onRootMenuItemClick = (event) => {
            emit("root-menuitem-click", event)
        }

        return {
            isAdmin,
            isOperator,
            isGestor,
            isProgramador,
            baseMenu,
            filtrarMenu,
            onSidebarMouseOver,
            onSidebarMouseLeave,
            onToggleMenu,
            onMenuClick,
            onMenuItemClick,
            onRootMenuItemClick
        }
    }
}
</script>
