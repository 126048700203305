export default (api, resource) => ({
    getByCity: async({token,params})=>{
        const response = await api.get( resource+'/reports/cobranca-por-cidade' ,{
            params,
            headers:{
                Authorization: token
            }
        })
        return {
            data: response.data
        }
    },


    getAll: async({token,rota,params})=>{

        let url = `${resource}/reports`

        if(rota && rota === "relatorioRecolhe"){
            url = `${url}/recolhe`
        }

        if(rota && rota === "posicaoRecolhe"){
            url = `${url}/relatorio-cobranca`
        }

        if(rota && rota === "cobranca"){
            url = `${url}/cobranca`
        }

        const response = await api.get( url ,{
            params,
            headers:{
                Authorization: token
            }
        })

        return{
            data: response.data 
        }
    },

    
    recolherSaldo: async({token,params})=>{
        const response = await api.post(`${resource}/${params.estabelecimentoId}/recolher`,params,{
            headers:{
                Authorization: token
            },
        })

        return{
            data: response.data 
        }
    },
})