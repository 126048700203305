<template>
    <div class="layout-rightpanel flex flex-column justify-content-between p-2" @click="updateRightMenuClick">
        <div class="p-2">
            <div class="card border-round">
                <!--TÍTULOS-->
                <div>
                    <div v-if="state.editUser === 1" class="flex justify-content-between align-items-center gap-3 text-center font-bold text-lg">
                        <span>INFORMAÇÕES DO USUÁRIO</span>

                        <div v-if="state.editUser === 1">
                            <Button @click="proximoPasso()" class="p-button-primary p-button-sm" 
                            icon="pi pi-pencil" v-tooltip.top="'Editar'"/>
                        </div>
                    </div>

                    <div v-if="state.editUser === 2" class="flex justify-content-between align-items-center gap-3 text-center font-bold text-lg">
                        <span>EDITAR INFORMAÇÕES</span>
                        <i class="pi pi-user-edit" style="font-size: 1.5rem;"/>
                    </div>

                    <div v-if="state.editUser === 3" class="flex justify-content-between align-items-center gap-3 text-center font-bold text-lg">
                        <span>ALTERAR SENHA</span>
                        <i class="pi pi-lock" style="font-size: 1.5rem;"/>
                    </div>
                </div>
                <hr class="mb-5 mt-2">

                <!--INFORMACOES USUARIO-->
                <div v-if="state.editUser === 1" class="flex flex-column gap-4">  
                    <div class="flex justify-content-between">
                        <div class="font-medium">LOGIN</div>
                        <div>{{usuario.login}}</div>
                    </div>

                    <div class="flex justify-content-between">
                        <div class="font-medium">NOME</div>
                        <div>{{usuario.nome}}</div>
                    </div>

                    <div v-if="!isAdmin" class="flex justify-content-between">
                        <div class="font-medium">TELEFONE</div>
                        <div>{{usuario.telefone}}</div>
                    </div>
                </div>

                <!--EDITAR INFORMACOES-->
                <div class="mb-5">
                    <div v-if="state.editUser === 2">
                        <div class="mb-3">
                            <div class="font-medium mb-1">Login:</div>
                            <span class="block p-input-icon-right">
                                <i class="pi pi-lock" />
                                <InputText class="p-inputtext-sm w-full" disabled v-model="usuario.login"></InputText>
                            </span>
                        </div> 

                        <div class="mb-3">
                            <div class="font-medium mb-1">Nome:</div>
                            <InputText class="p-inputtext-sm w-full" v-model="state.user.nome" :class="{'p-invalid': !state.user.nome && state.enviado}"/>
                        </div>
                    
                        <div v-if="!isAdmin" class="mb-3">
                            <div class="font-medium mb-1">Telefone:</div>
                            <InputMask mask="(99) 99999-9999" class="p-inputtext-sm w-full" v-model="state.user.telefone" :class="{'p-invalid': !state.user.telefone && state.enviado}"/>
                        </div>

                        <span style="color:rgb(72, 121, 253); cursor: pointer;" @click="proximoPasso()">Alterar senha</span>
                    </div>
                    
                    <!--ALTERAR SENHA-->
                    <div v-if="state.editUser === 3" class="mb-5">
                        <div class="mb-3">
                            <div class="font-medium mb-1">Nova Senha:</div>
                            <InputText class="p-inputtext-sm w-full" v-model="state.user.senha" :class="{'p-invalid': state.user.senha !== state.confirmacaoSenha && state.enviado}"/>
                        </div>

                        <div class="mb-3">
                            <div class="font-medium mb-1">Confirmar Senha:</div>
                            <InputText class="p-inputtext-sm w-full" v-model="state.confirmacaoSenha" :class="{'p-invalid': state.user.senha !== state.confirmacaoSenha && state.enviado}"/>
                        </div>  
                    </div>
                </div>

                <!--BOTÕES EDITAR INFORMACOES-->
                <div class="btn-editar-informacoes">
                    <div v-if="state.editUser === 2" class="flex align-items-center justify-content-center gap-2">
                        <Button @click="voltarPasso()" class="p-button-primary p-button-outlined w-7rem" label="Cancelar" icon="pi pi-times"></Button>
                        <Button @click="editarUsuario()" :loading="stateUsers.loading.editarUsuario" class="p-button-primary w-7rem" 
                        label="Salvar" icon="pi pi-check"></Button>
                    </div>

                    <div v-if="state.editUser === 3" class="flex align-items-center justify-content-center gap-2">
                        <Button @click="voltarPasso()" class="p-button-primary p-button-outlined w-7rem " label="Cancelar" icon="pi pi-times"></Button>
                        <Button @click="alterarSenha()" :loading="stateUsers.loading.alterarSenha" class="p-button-primary w-7rem" 
                        :disabled="!state.user.senha || !state.confirmacaoSenha" label="Salvar" icon="pi pi-check"></Button>
                    </div>
                </div>
            </div>
        </div>

        <!--BOTÃO SAIR-->
        <div class="logout-btn flex justify-content-end">
            <Button @click="logout()" label="Sair" icon="pi pi-sign-out" class="p-button-danger"></Button>
        </div>
    </div>
</template>
  
<script>
import { useStore } from 'vuex'
import services from './services'
import { useRouter } from 'vue-router'           
import { computed,  watch, reactive } from 'vue'
import useNotificationToast from './composables/useNotificationToast'
import useUsers from './composables/useUsers'

export default{

    name: "AppRightPanel",
    emits: ["right-menu-click", "update:rightMenuClick"],
    props: {rightMenuActive: Boolean,},
    
    setup(props, { emit }) {

        const updateRightMenuClick = () => {
            emit('update:rightMenuClick', true)
            emit('right-menu-click', true)
        }

        const state = reactive({
            editUser: 1,

            user:{
                nome: '',
                senha:'',
                telefone:''
            },
            confirmacaoSenha: '',

            enviado: false
        })

        const store = useStore()
        const router = useRouter()
        const isAdmin = computed(()=> store.getters.isAdmin)
        const usuario = computed(()=> store.getters.getUsuario)
        
        //composables
        const { stateUsers } = useUsers()
        const { success, error} = useNotificationToast()

        //observa se o painel do usuario está on/off
        watch(() => props.rightMenuActive, (newVal) => {
            if (!newVal) {
                state.editUser = 1
            }
        })

        async function editarUsuario(){
            stateUsers.loading.editarUsuario = true
            state.enviado = true

            switch (true) {
                case (isAdmin.value && !state.user.nome):
                    stateUsers.loading.editarUsuario = false
                    return error('Não deixe os campos vazios')
    
                case (!isAdmin.value && (!state.user.telefone || !state.user.nome)):
                    stateUsers.loading.editarUsuario = false
                    return error('Não deixe os campos vazios')

                default: break      
            }

            const token = store.getters.getToken

            if(isAdmin.value === true){
                state.user = {
                    id: usuario.value.id,
                    nome: state.user.nome,
                }
            }

            state.user = {
                id: usuario.value.id,
                nome: state.user.nome,
                telefone: state.user.telefone
            }

            try {
                await services.users.updateUser({user:state.user,token})
                success('Usuário atualizado com sucesso!')
                state.editUser = 1
                usuario.value.nome = state.user.nome
                usuario.value.telefone = state.user.telefone
            } 
            catch (e) {
                const errorMessage = e.response?.data?.message || 'Erro ao editar usuário.'
            error(errorMessage)
            }
            finally{
                state.enviado = false
                stateUsers.loading.editarUsuario = false
            }
        }

        async function alterarSenha(){
            stateUsers.loading.alterarSenha = true
            state.enviado = true

            const token = store.getters.getToken

            state.user = {
                login : usuario.value.login,
                senha: state.user.senha,
                confirmacaoSenha: state.confirmacaoSenha
            }

            try {
                await services.auth.changePassword({user:state.user,token})
                success('Senha alterada com sucesso!')
                state.editUser = 1
            } catch (e) {
                stateUsers.loading.alterarSenha = false
                const errorMessage = e.response?.data?.data?.message || 'Não foi possível alterar a senha.'
                error(errorMessage)
            }
            finally{
                stateUsers.loading.alterarSenha = false
            }
        }

        function proximoPasso(){
            state.editUser++
            state.enviado = false
            limparCampos()
        }

        function voltarPasso(){
            state.editUser--
            state.enviado = false
            limparCampos()
        }

        function limparCampos(){
            state.user.senha = ''
            state.confirmacaoSenha = ''
            state.user.nome = usuario.value.nome
            state.user.telefone = usuario.value.telefone
        }

        function logout(){
            router.push({name: 'login'})

            store.dispatch('setLogged', {
                isLogged: false
            })
        }





        return {
            updateRightMenuClick,
            logout,
            alterarSenha,
            proximoPasso,
            voltarPasso,
            editarUsuario,
            limparCampos,
            stateUsers,
            state,
            usuario,
            isAdmin
        }
    }
}
</script>
